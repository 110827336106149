import dataEntry from '../../assets/iconpng/newicons/keyboard.png'
import nike from '../../assets/icons/nike.png'
import users from '../../assets/iconpng/newicons/users.png'
import categoryicon from '../../assets/icons/category.png'
import updateicon from '../../assets/icons/update.png'
import producticon from '../../assets/icons/product.png'
import invoiceicon from '../../assets/icons/invoice.png'

export const checkicon=(icon)=>{
    // console.log('icon ='+icon)
    return icon=="dataEntry"?dataEntry:
    icon=="nike"?nike:
    icon=="users"?users:
    icon=="categoryicon"?categoryicon:
    icon=="updateicon"?updateicon:
    icon=="producticon"?producticon:
    icon=="invoiceicon"?invoiceicon:null
  }