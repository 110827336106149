import React, { useState ,useEffect
,useRef
} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
  ,TextInput,Image,  Picker,Button,Modal,ScrollView
,FlatList
  } from 'react-native'

    import DateTimePicker from 'react-datetime-picker';

    import NavBar from '../../navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../../../assets/colors/colors';
import NavBar2 from '../../navbar/NavBar2';
import NavBar3 from '../../navbar/NavBar3';

import world from '../../../assets/iconpng/gray/world.png'
import saveimg from '../../../assets/iconpng/black/save.png'
import {styles} from '../../../assets/styleSAP/formaInputSap'
import {datePickerStyles} from '../../../assets/styleSAP/datePickerStyles'
import {searchPicker} from '../../../assets/styleSAP/searchPicker'


import axios from 'axios';
import { api, icons, imageAPI } from '../../../assets/assets';





const EditProduct =({navigation,route})=>{
    const pageName="Edit product"
    const { item} = route.params;
    const Imagecard = ({ item ,img, }) => {
      return(
        <TouchableOpacity
        key={item.id}
        onPress={() => handleImgValueChange(item.imgurl)}
        style={searchPicker.pickerItem}
      >
  <Image
  source={{uri: item.imgurl}}
  style={{width: 250, height: 250}}
  />                  
  <Text>{item.id}</Text>             
  
      </TouchableOpacity>
      )
    }

    const nav=useSelector(state =>state.navbar.nav2Active);

    // error 
      const [error, setError] = useState(null);

//product name
const [nameAr, setnameAr] = useState(item.name_ar); //done
const [nameEn, setnameEn] = useState(item.name_en); //done


//images
const [imgSearch, setImgSearch] = useState('');
const [imgs,setimgs]=useState(imageAPI)

const [selectedimgurl, setSelectedimgUrl] = useState('');

const [isImgPickerVisible, setImgPickerVisible] = useState(false);
const [filteredimgs, setFilteredimgs] = useState(imgs);


const handleSearchimg= (query) => {
  const filtered = imgs.filter((img) =>
    img.imgurl.toLowerCase().includes(query.toLowerCase())
  );
  setFilteredimgs(filtered);
  setImgSearch(query);
};

const handleImgValueChange = (value) => {
  //setSelectedimgUrl(value);
  if(selectedimgurl==="image1"){setImage1(value)}
  if(selectedimgurl==="image2"){setImage2(value)}
  if(selectedimgurl==="image3"){setImage3(value)}
  if(selectedimgurl==="image4"){setImage4(value)}


  setImgPickerVisible(false);
};
const [image1, setImage1] = useState(item.image1); //done
const [image2, setImage2] = useState(item.image2); //done
const [image3, setImage3] = useState(item.image3); //done
const [image4, setImage4] = useState(item.image4); //done


const fileInputRef1 = useRef();
const fileInputRef2 = useRef();
const [newimage1,setnewimage1]=useState('null');
const handelNewImage1 = file => {
  if (file[0] && file[0].size > 500000) {
    // File size exceeds the limit (500KB in this example)
    alert('File size exceeds the limit.حجم الصورة يجب ان يكون ٥٠٠ كيلو بايت او اقل ');
    // Optionally, you can clear the file input field
    setnewimage1('null');
    setNewImages(['null',newimage2,newimage3,newimage4])

    
  } else {
  setnewimage1(file[0]);
  setNewImages([file[0],newimage2,newimage3,newimage4])
  }
  

}
const [newimage2,setnewimage2]=useState('null');
const handelNewImage2 = file => {
  if (file[0] && file[0].size > 500000) {
    // File size exceeds the limit (500KB in this example)
    alert('File size exceeds the limit.حجم الصورة يجب ان يكون ٥٠٠ كيلو بايت او اقل ');
    // Optionally, you can clear the file input field
    setnewimage2('null');
    setNewImages([newimage1,'null',newimage3,newimage4])

    
  } else {
  setnewimage2(file[0]);
  setNewImages([newimage1,file[0],newimage3,newimage4])
  }

}
const [newimage3,setnewimage3]=useState('null');
const handelNewImage3 = file => {
  if (file[0] && file[0].size > 500000) {
    // File size exceeds the limit (500KB in this example)
    alert('File size exceeds the limit.حجم الصورة يجب ان يكون ٥٠٠ كيلو بايت او اقل ');
    // Optionally, you can clear the file input field
    setnewimage3('null');
  setNewImages([newimage1,setnewimage2,'null',newimage4])

    
  } else {
    setnewimage3(file[0]);
    setNewImages([newimage1,setnewimage2,file[0],newimage4])
  }
  
}

const [newimage4,setnewimage4]=useState('null');
const handelNewImage4 = file => {
  if (file[0] && file[0].size > 500000) {
    // File size exceeds the limit (500KB in this example)
    alert('File size exceeds the limit.حجم الصورة يجب ان يكون ٥٠٠ كيلو بايت او اقل ');
    // Optionally, you can clear the file input field
    setnewimage4('null');
    setNewImages([newimage1,setnewimage2,newimage3,'null'])

    
  } else {
    setnewimage4(file[0]);
    setNewImages([newimage1,setnewimage2,newimage3,file[0]])
  }
  
}

const [newImages, setNewImages] = useState(null);

const submitNewImage = () =>{
  const formData = new FormData();
  [newimage1,newimage2,newimage3,newimage4].forEach((image) => {
    formData.append('images[]', image);
  });
  axios({
    method: "post",
    url: api+"uploadimage",

    headers: { 
        'Accept': 'application/json',
        'Authorization': 'Bearer ',
        "Content-Type": "multipart/form-data"

    },
    data:formData
  
})
.then((response) => {
     console.log(response.data.image1);
     handleAddProduct(
      newimage1=="null"?item.image1: response.data.image1,
      newimage2=="null"?item.image2:response.data.image2,
      newimage3=="null"?item.image3:response.data.image3,
      newimage4=="null"?item.image4:response.data.image4
     )
     setnewimage1("null")
     setnewimage2("null")
     setnewimage3("null")
     setnewimage4("null")
})
.catch((error) => {
    alert("add the photos");
});

}






const [descriptionAr, setDescriptionAr] = useState(item.description_ar);
const [descriptionEn, setDescriptionEn] = useState(item.description_en);


  const [scancode, setScancode] = useState(item.scancode);//done
  const [discount, setDiscount] = useState(item.discount);//done
  const [amount, setAmount] = useState(item.amount);//done
  


const [productState, setProductState] = useState(item.state);//done
const [productStatusArray, setProductStatusArray] = useState([
  {id:1,state:"ready"},
  {id:2,state:"Not Ready"},
  {id:3,state:"Not for Sale"}
]);
const handleProductStatusChange = (value) => {
  setProductState(value);
};


const [at_id1, setAt_id1] = useState(item.at_id1);//done
const [at_id2, setAt_id2] = useState(item.at_id2);//done
const [at_id3, setAt_id3] = useState(item.at_id3);//done
const [at_id4, setAt_id4] = useState(item.at_id4);//done

const [atArray, setAtArray] = useState([
  "1",
  "2",
  "3",
]);

const handleAt_id1Change = (value) => {
  setAt_id1(value);
};

const handleAt_id2Change = (value) => {
  setAt_id2(value);
};

const handleAt_id3Change = (value) => {
  setAt_id3(value);
};

const handleAt_id4Change = (value) => {
  setAt_id4(value);
};



const [category_id1, setCategory_id1] = useState(item.category_id1);
const [category_id2, setCategory_id2] = useState(item.category_id2);
const [category_id3, setCategory_id3] = useState(item.category_id3);
const [category_id4, setCategory_id4] = useState(item.category_id4);
const [categories, setcategories] = useState([
  {
    id: 1,
    nameAr: "Category 1",
  },
  {
    id: 2,
    nameAr: "Category 2",
  },
  {
    id: 3,
    nameAr: "Category 3",
  },
  {
    id: 4,
    nameAr: "Category 4",
  },
]);

const handleCategory_id1Change = (value) => {
  setCategory_id1(value);
};

const handleCategory_id2Change = (value) => {
  setCategory_id2(value);
};

const handleCategory_id3Change = (value) => {
  setCategory_id3(value);
};

const handleCategory_id4Change = (value) => {
  setCategory_id4(value);
};
      const [formError, setFormError] = useState(false);

   
      const [productCompanyId, setproductCompanyId] = useState(item.productCompanyId);
      const [trademarkds, settrademarks] = useState([])




      const handleAddProduct = (image1,image2,image3,image4) => {
        if (isValidUser()) {
          const newUser = {
            name_ar: nameAr,
            name_en:nameEn,
            image1:image1,
            image2:image2,
            image3:image3,
            image4:image4,
            description_ar:descriptionAr,
            description_en:descriptionEn,
            scancode:scancode,
            discount:discount,
            amount:amount,
            state:productState,
            at_id1:at_id1,
            at_id2:at_id2,
            at_id3:at_id3,
            at_id4:at_id4,
            category_id1:category_id1,
            category_id2:category_id2,
            category_id3:category_id3,
            category_id4:category_id4,
            atId5:0,
            atId6:0,
            atId7:0,
            atId8:0,
            viewImage:"null",
            productCompanyId:productCompanyId
          };
          //console.log(newUser);
          axios({
            method: "put",
            url: api+"items/"+item.id,
            headers: { 
                'Accept': 'application/json',
                'Authorization': 'Bearer '
            },
            data:newUser
        })
        .then((response) => {
             console.log(response.data);
             alert("update done")
             setError("done")
        })
        .catch((error) => {
          console.log(error.response.data)
        });
        } else {
          console.log('Invalid category!' + isValidUser() );
        }
      };
    
 

      const isValidUser = () => {
        if (
            nameAr !== '' &&
            nameEn !== '' &&
            image1 !== '' &&
            image2 !== '' &&
            image3 !== '' &&
            image4 !== '' &&
            descriptionAr !== '' &&
            descriptionEn !== '' &&
            scancode !== '' &&
            discount !== '' &&
            amount !== '' &&
            at_id1 !== '' &&
            at_id2 !== '' &&
            at_id3 !== '' &&
            at_id4 !== '' &&
            category_id1 !== '' &&
            category_id2 !== '' &&
            category_id3 !== '' &&
            category_id4 !== '' &&
            productCompanyId !== '' 


        ) {
          setError('')
          return true;
        } else {
          let message = 'Invalid user: ';
          setError(message)
         if ( nameAr === '') {
            setError( message += 'nameAr is empty.');
          }
          else if ( nameEn === '') {
            setError( message += 'nameEn is empty.');
          }
          else if ( image1 === '') {
            setError( message += 'image1 is empty.');
          }
          else if ( image2 === '') {
            setError( message += 'image2 is empty.');
          }
          else if ( image3 === '') {
            setError( message += 'image3 is empty.');
          }
          else if ( image4 === '') {
            setError( message += 'image4 is empty.');
          }
          else if ( descriptionAr === '') {
            setError( message += 'descriptionAr is empty.');
          }
          else if ( descriptionEn === '') {
            setError( message += 'descriptionEn is empty.');
          }
          else if ( scancode === '') {
            setError( message += 'scancode is empty.');
          }
          else if ( discount === '') {
            setError( message += 'discount is empty.');
          }
          else if ( amount === '') {
            setError( message += 'amount is empty.');
          }
          else if ( at_id1 === '') {
            setError( message += 'at_id1 is empty.');
          }
          else if ( at_id2 === '') {
            setError( message += 'at_id2 is empty.');
          }
          else if ( at_id3 === '') {
            setError( message += 'at_id3 is empty.');
          }
          else if ( at_id4 === '') {
            setError( message += 'at_id4 is empty.');
          }
          else if ( category_id1 === '') {
            setError( message += 'category_id1 is empty.');
          }
          else if ( category_id2 === '') {
            setError( message += 'category_id2 is empty.');
          }
          else if ( category_id3 === '') {
            setError( message += 'category_id3 is empty.');
          }
          else if ( category_id4 === '') {
            setError( message += 'category_id4 is empty.');
          }
          else if ( productCompanyId === '') {
            setError( message += 'trademark is empty.');
          }
          else{
            setError( message += 'error');

          }
          
          return false;
        }
      };

      useEffect(() => {
        axios({
          method: "get",
          url: api+'category',
          headers: { 
              'Accept': 'application/json',
              'Authorization': 'Bearer '
          }
      })
      .then((response) => {
           console.log(response.data);
           setcategories(response.data)
      })
      .catch((error) => {
          console.log(error);
      });

      axios({
        method: "get",
        url: api+'trademark',
        headers: { 
            'Accept': 'application/json',
            'Authorization': 'Bearer '
        }
    })
    .then((response) => {
         console.log(response.data);
         settrademarks(response.data)
    })
    .catch((error) => {
        console.log(error);
    });
      }, [])
    return(
      <View style={[styles.container,{minHeight:1080}]}>
      <NavBar navigation={navigation}/>
      <NavBar2 navigation={navigation} nav2active={3}/>
      <NavBar3 navigation={navigation} nav2active={2}/>



        
        <View style={styles.container2}>
        <Text >{pageName} page</Text>

         

          {/* all text input */}
          <View>


{/* genral info  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>product name</Text>


      {/* 4 row input */}
<View style={styles.ViewInput2Row}>

 {/* input nameAr */}
 <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Name in arabic*</Text>
<TextInput
  value={nameAr}
  onChangeText={setnameAr}
  placeholder="Enter product name in arabic"
  style={styles.TextInput}

/>
</View>
{/* end of input Name */}

  {/* input nameEn */}
  <View style={styles.inputView}>
      <Text style={styles.inputHeaderText}>name in english</Text>
      <TextInput
        value={nameEn}
         onChangeText={setnameEn}
        placeholder="Enter category name in english"
        style={styles.TextInput}

      />
      </View>
      {/* end of input email */}
        </View>
      </View>
      {/* end of genral info  */}


{/* images  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>new product images</Text>
 {/* 4 row input */}
 <View style={styles.ViewInput2Row}>
 <View style={styles.inputView}>
  <View style={{flexDirection:"column"}}>
  <Text>image 1</Text>

  <input name="imgae" id='image' type='file'
              ref={fileInputRef1}

  onChange={e => handelNewImage1(e.target.files)}
  
  />
   <img
            src={newimage1!='null' ? URL.createObjectURL(newimage1) : item.image1}
            alt="Image 1 Preview"
            style={{width:200,height:200}}
            onClick={()=>fileInputRef1.current.click()}
          />
  </View>

 </View>


 <View style={styles.inputView}>
 <View style={{flexDirection:"column"}}>

  <Text>image 2</Text>
  <input name="imgae2" id='image2' type='file'
  onChange={e => handelNewImage2(e.target.files)}
  />
  <img
            src={newimage2!='null' ? URL.createObjectURL(newimage2) : item.image2}
            alt="Image 2 Preview"
            style={{width:200,height:200}}

          />
          </View>
 </View>


 <View style={styles.inputView}>
 <View style={{flexDirection:"column"}}>

  <Text>image 3</Text>
  <input name="imgae3" id='image3' type='file'
  onChange={e => handelNewImage3(e.target.files)}
  />
  <img
            src={newimage3!='null' ? URL.createObjectURL(newimage3) : item.image3}
            alt="Image 3 Preview"
            style={{width:200,height:200}}

          />
          </View>
 </View>


 <View style={styles.inputView}>
 <View style={{flexDirection:"column"}}>

  <Text>image 4</Text>
  <input name="imgae4" id='image4' type='file'
  onChange={e => handelNewImage4(e.target.files)}
  />
  <img
            src={newimage4!='null' ? URL.createObjectURL(newimage4) : item.image4}
            alt="Image 4 Preview"
            style={{width:200,height:200}}

          />
          </View>
 </View>
 </View>
{/* 
 <TouchableOpacity 
  onPress={()=>submitNewImage()}
  >
    <Text>
      submitNewImage
    </Text>
  </TouchableOpacity> */}
 </View>






{/* description  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>product description</Text>
 {/* 4 row input */}
 <View style={styles.ViewInput2Row}>
 <View style={styles.inputView}>
        <Text style={styles.inputHeaderText}>Description in arabic*</Text>
        <TextInput
          value={descriptionAr}
          onChangeText={setDescriptionAr}
          placeholder="Enter description in arabic"
          style={styles.TextInput}
        />
      </View>
      <View style={styles.inputView}>
      <Text style={styles.inputHeaderText}>Description in english*</Text>
        <TextInput
          value={descriptionEn}
          onChangeText={setDescriptionEn}
          placeholder="Enter description in english"
          style={styles.TextInput}
        />
      </View>

      <View style={styles.inputView}>
        <Text style={styles.inputHeaderText}>Scancode</Text>
        <TextInput
          value={scancode}
          onChangeText={setScancode}
          placeholder="Enter scancode"
          style={styles.TextInput}
        />
      </View>

 </View>
 </View>
 {/* end of describtion */}


 {/* description  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>product price</Text>
 {/* 4 row input */}
 <View style={styles.ViewInput2Row}>


      <View style={styles.inputView}>
        <Text style={styles.inputHeaderText}>Amount</Text>
        <TextInput
          value={amount}
          onChangeText={setAmount}
          placeholder="Enter amount"
          style={styles.TextInput}
        />
      </View>

      <View style={styles.inputView}>
        <Text style={styles.inputHeaderText}>Discount</Text>
        <TextInput
          value={discount}
          onChangeText={setDiscount}
          placeholder="Enter discount"
          style={styles.TextInput}
        />
      </View>

 </View>

    
 </View>
 {/* end of price and amount */}



 {/* product state  */}
 <View style={styles.formaView}>
<Text style={styles.formaViewHeader}>product state</Text>
 {/* 4 row input */}
 <View style={styles.ViewInput2Row}>
 <View style={styles.inputView}>
  <Text style={styles.inputHeaderText}>Product Status</Text>
  <Picker
    selectedValue={productState}
    onValueChange={handleProductStatusChange}
    style={styles.TextInput}>
    {productStatusArray.map((item, index) => (
      <Picker.Item
        key={item.id}
        label={item.state}
        value={item.state}
      />
    ))}
  </Picker>
</View>
  </View>

  </View>
 {/* end of product state  */}

  {/* at  */}
  {/* <View style={styles.formaView}>
<Text style={styles.formaViewHeader}>product atribute</Text>
 <View style={styles.ViewInput2Row}>
 <View style={styles.inputView}>
  <Text style={styles.inputHeaderText}>At_id1</Text>
  <Picker
    selectedValue={at_id1}
    onValueChange={handleAt_id1Change}
    style={styles.TextInput}>
    {atArray.map((status, index) => (
      <Picker.Item
        key={index}
        label={status}
        value={status}
      />
    ))}
  </Picker>
</View>
<View style={styles.inputView}>
  <Text style={styles.inputHeaderText}>At_id2</Text>
  <Picker
    selectedValue={at_id2}
    onValueChange={handleAt_id2Change}
    style={styles.TextInput}>
    {atArray.map((status, index) => (
      <Picker.Item
        key={index}
        label={status}
        value={status}
      />
    ))}
  </Picker>
</View>
<View style={styles.inputView}>
  <Text style={styles.inputHeaderText}>At_id3</Text>
  <Picker
    selectedValue={at_id3}
    onValueChange={handleAt_id3Change}
    style={styles.TextInput}>
    {atArray.map((status, index) => (
      <Picker.Item
        key={index}
        label={status}
        value={status}
      />
    ))}
  </Picker>
</View>
<View style={styles.inputView}>
  <Text style={styles.inputHeaderText}>At_id4</Text>
  <Picker
    selectedValue={at_id4}
    onValueChange={handleAt_id4Change}
    style={styles.TextInput}>
    {atArray.map((status, index) => (
      <Picker.Item
        key={index}
        label={status}
        value={status}
      />
    ))}
  </Picker>
</View>
 </View>

</View> */}
{/* end of at*/}

 {/* catigores */}
 <View style={styles.formaView}>
<Text style={styles.formaViewHeader}>catigores</Text>
 {/* 4 row input */}
 <View style={styles.ViewInput2Row}>
 <View style={styles.inputView}>
  <Text style={styles.inputHeaderText}>Category_id1</Text>
  <Picker
    selectedValue={category_id1}
    onValueChange={handleCategory_id1Change}
    style={styles.TextInput}>
    {categories.map((category, index) => (
      <Picker.Item
        key={index}
        label={category.nameAr}
        value={category.id}
      />
    ))}
  </Picker>
</View>
<View style={styles.inputView}>
  <Text style={styles.inputHeaderText}>Category_id2</Text>
  <Picker
    selectedValue={category_id2}
    onValueChange={handleCategory_id2Change}
    style={styles.TextInput}>
    {categories.map((category, index) => (
      <Picker.Item
        key={index}
        label={category.nameAr}
        value={category.id}
      />
    ))}
  </Picker>
</View>
<View style={styles.inputView}>
  <Text style={styles.inputHeaderText}>Category_id3</Text>
  <Picker
    selectedValue={category_id3}
    onValueChange={handleCategory_id3Change}
    style={styles.TextInput}>
    {categories.map((category, index) => (
      <Picker.Item
        key={index}
        label={category.nameAr}
        value={category.id}
      />
    ))}
  </Picker>
</View>

 </View>
 <View style={styles.inputView}>
  <Text style={styles.inputHeaderText}>Category_id4</Text>
  <Picker
    selectedValue={category_id4}
    onValueChange={handleCategory_id4Change}
    style={styles.TextInput}>
    {categories.map((category, index) => (
      <Picker.Item
        key={index}
        label={category.nameAr}
        value={category.id}
      />
    ))}
  </Picker>
</View>
</View>
 {/* end of catigores   */}

 {/* trademark */}
{/* <View style={styles.formaView}>
<Text style={styles.formaViewHeader}>trademark</Text>
 <View style={styles.ViewInput2Row}>
 <View style={styles.inputView}>
  <Text style={styles.inputHeaderText}>trademark</Text>
  <Picker
    selectedValue={productCompanyId}
    onValueChange={setproductCompanyId}
    style={styles.TextInput}>
    {trademarkds&&trademarkds.map((trade, index) => (
      <Picker.Item
        key={index}
        label={trade.name_ar}
        value={trade.id}
      />
    ))}
  </Picker>
</View>
  </View>
  </View> */}
  {/* end of trademark */}

      
{/* start of modal images */}
<Modal visible={isImgPickerVisible} animationType="slide">
        <View style={searchPicker.modalContainer}>
        <TouchableOpacity onPress={() => setImgPickerVisible(false)}
        style={searchPicker.closeButton}
        >
              <Text style={searchPicker.closeButtonText}>Close</Text>
            </TouchableOpacity>


          <View style={styles.searchContainer}>
            <TextInput
              style={searchPicker.searchInput}
              onChangeText={handleSearchimg}
              value={imgSearch}
              placeholder="Search img"
            />
          </View>


          <ScrollView>

          <View style={styles.pickerContainer}>
            <View style={styles.picker}>
            <FlatList

data={filteredimgs}
keyExtractor={item => item.id}
numColumns={5}
renderItem={({ item ,index}) => (
  <Imagecard item={item}  />
)}

/>
            </View>
          </View>
          </ScrollView>

    

        </View>
      </Modal>
{/* end of modal images */}


</View>
        </View>
        
        <View style={styles.saveView}>
            {error && <Text style={{ color: 'red' }}>{error}</Text>}

                <TouchableOpacity style={styles.Button}        

                 onPress={()=>
                  
                  newImages==null?handleAddProduct(image1,
                    image2,image3,image4
                    ):
                  submitNewImage()}

      disabled={!isValidUser}
>
<Text style={styles.saveBtnText}>update</Text>
<Image 
                source={icons.updateicon}  
                style={{width: 19, height: 19 ,margin:5}}
            />
                </TouchableOpacity>

            </View>

           
</View>
    )
}

export default EditProduct;
