import React, { useState ,useEffect} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
    ,TextInput,Image,  Picker,Button

    } from 'react-native'

    import DateTimePicker from 'react-datetime-picker';

    import NavBar from '../../navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../../../assets/colors/colors';
import NavBar2 from '../../navbar/NavBar2';
import NavBar3 from '../../navbar/NavBar3';

import world from '../../../assets/iconpng/gray/world.png'
import saveimg from '../../../assets/iconpng/black/save.png'
import {styles} from '../../../assets/styleSAP/formaInputSap'
import {datePickerStyles} from '../../../assets/styleSAP/datePickerStyles'

import axios from 'axios';
import { api, icons } from '../../../assets/assets';





const EditCategory =({navigation,route})=>{
    const pageName="category"
    const { item} = route.params;

  const [categories,setcategories] = useState([
  ]);
    const nav=useSelector(state =>state.navbar.nav2Active);

    // error 
      const [error, setError] = useState(null);

      //Category data
      
      const [categorynumber, setcategorynumber] = useState(item.categorynumber);
      const [nameAr, setnameAr] = useState(item.nameAr);
      const [nameEn, setnameEn] = useState(item.nameEn);
      const [mainCategoryId, setmainCategoryId] = useState(item.mainCategoryId);

      

  
    
      const [formError, setFormError] = useState(false);

   
      const handleSubmit = () => {
        if (
            categorynumber !== '' &&
            nameAr !== '' &&
            nameEn !== '' &&
            mainCategoryId !== '' 
        ) {
          console.log('Form is valid. Submitting...');
          // TODO: Add logic to submit the form data
        } else {
          setFormError(true);
        }
      };




      const handleUpdateCategory = () => {
        if (isValidUser()) {
          const newUser = {
            categorynumber: categorynumber,
            nameAr: nameAr,
            nameEn:nameEn,
            mainCategoryId:mainCategoryId,
          };
          //console.log(newUser);
          axios({
            method: "put",
            url: api+"category/"+item.id,
            headers: { 
                'Accept': 'application/json',
                'Authorization': 'Bearer '
            },
            data:newUser
        })
        .then((response) => {
             console.log(response.data);
             alert("update done")
             setError("done")
        })
        .catch((error) => {
          console.log(error.response.data)
        });
        } else {
          console.log('Invalid category!' + isValidUser() );
        }
      };
    
 

      const isValidUser = () => {
        if (
            categorynumber !== '' &&
            nameAr !== '' &&
            nameEn !== '' &&
            mainCategoryId !== '' 
        ) {
          setError('')
          return true;
        } else {
          let message = 'Invalid user: ';
          setError(message)
          if ( categorynumber === '') {
            setError( message += 'categorynumber is empty. ');
          }
          else if ( nameAr === '') {
            setError( message += 'nameAr is empty.');
          }
          else if ( nameEn === '') {
            setError( message += 'nameEn is empty.');
          }
          else if (mainCategoryId === '') {
            setError( message += 'mainCategoryId is empty. ');
          }
          else{
            setError( message += 'error');

          }
          
          return false;
        }
      };

      useEffect(() => {
        axios({
          method: "get",
          url: api+'category',
          headers: { 
              'Accept': 'application/json',
              'Authorization': 'Bearer '
          }
      })
      .then((response) => {
           console.log(response.data);
           setcategories(response.data)
          
      })
      .catch((error) => {
          console.log(error);
      });
      }, [])
    return(
      <View style={[styles.container,{minHeight:1080}]}>
      <NavBar navigation={navigation}/>
      <NavBar2 navigation={navigation} nav2active={2}/>
      <NavBar3 navigation={navigation} nav2active={2}/>



        
        <View style={styles.container2}>
        <Text >edit {pageName} page</Text>

         

          {/* all text input */}
          <View>


{/* genral info  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>category info</Text>


      {/* 4 row input */}
<View style={{flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>

 {/* input nameAr */}
 <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Name in arabic*</Text>
<TextInput
  value={nameAr}
  onChangeText={setnameAr}
  placeholder="Enter category name in arabic"
  style={styles.TextInput}

/>
</View>
{/* end of input Name */}

  {/* input nameEn */}
  <View style={styles.inputView}>
      <Text style={styles.inputHeaderText}>name in english</Text>
      <TextInput
        value={nameEn}
         onChangeText={setnameEn}
        placeholder="Enter category name in english"
        style={styles.TextInput}

      />
      </View>
      {/* end of input email */}


{/* input Area */}
<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>main Category</Text>
<Picker
selectedValue={mainCategoryId}
onValueChange={(value) => setmainCategoryId(value)}
style={styles.TextInput}


>
<Picker.Item
label={"no main category"}
value={"0"}

/>
{categories.map((categorie) => (
<Picker.Item
key={categorie.id}
label={categorie.nameAr}
value={categorie.id}
/>
))}
</Picker>
</View>
{/* end of input Area */}



        </View>
      </View>
      {/* end of genral info  */}








      



</View>
        </View>
        
        <View style={styles.saveView}>
            {error && <Text style={{ color: 'red' }}>{error}</Text>}

                <TouchableOpacity style={styles.Button}       
                 onPress={handleUpdateCategory}
     disabled={!isValidUser}
>
<Text style={styles.saveBtnText}>update</Text>
                <Image 
                source={icons.updateicon}  
                style={{width: 19, height: 19 ,margin:5}}
            />
                </TouchableOpacity>

            </View>

           
</View>
    )
}

export default EditCategory;
