import navs from "./navs";

const initalState = {
    nav3:[],      
};
const navbar3Reducer=(state = initalState,action)=>{

const {type,payload}=action;
//payload is the despatch function to do in the state

switch(type){

case "setCatigoriesNav3":return{...state,nav3:navs.categoryNav3}
case "setProductsNav3":return{...state,nav3:navs.productNav3}
case "setOrdersNav3":return{...state,nav3:navs.ordersNav3}
case "settradeNav3":return{...state,nav3:navs.tradeNav3}
case "setUsersNav3":return{...state,nav3:navs.userNav3}



}



return state;
}
export default navbar3Reducer;