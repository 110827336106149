
import dataEntry from '../../assets/iconpng/newicons/keyboard.png'

const initalState = {
    loginState:"false" ,
    phone:"",
    name:"",



    pages:[
        {id:1,pagname:"s"}
     ],
     mainNav:[],
     nav2:[],



     dataEntryNav2:[]

};
const checkicon=(icon)=>{
    console.log('icon ='+icon)
    return icon=="dataEntry"?dataEntry:null
  }
const login=(state = initalState,action)=>{
const {type,payload}=action;
//payload is the despatch function to do in the state

switch(type){
case "login":
    return{...state,
        loginState:"true",
        phone:payload.phone,
        name:payload.name,
        pages:payload.pages,
        mainNav:payload.mainNav,
        dataEntryNav2:payload.dataEntryNav2
    }

case "logout":
    return{...state,
        loginState:"false",
        phone:"",
        name:"",
        pages:[]
    }


    case "setDataEntry":return{...state,nav2:state.dataEntryNav2}


  
    
            

}



return state;
}
export default login;