import React, { useState ,useEffect} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
    ,TextInput,Image,  Picker,Button

    } from 'react-native'

    import DateTimePicker from 'react-datetime-picker';

    import NavBar from '../../navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../../../assets/colors/colors';
import NavBar2 from '../../navbar/NavBar2';
import NavBar3 from '../../navbar/NavBar3';

import world from '../../../assets/iconpng/gray/world.png'
import saveimg from '../../../assets/iconpng/black/save.png'
import {styles} from '../../../assets/styleSAP/formaInputSap'
import {datePickerStyles} from '../../../assets/styleSAP/datePickerStyles'

import axios from 'axios';
import { api } from '../../../assets/assets';





const EditAreas =({navigation,route})=>{

    const nav=useSelector(state =>state.navbar.nav2Active);
    const { item} = route.params;


    // error 
      const [error, setError] = useState(null);

      //Category data
      
      const [nameAr, setnameAr] = useState(item.nameAr);
      const [nameEn, setnameEn] = useState(item.nameEn);
      const [status, setstatus] = useState(item.status);

      const [StatusArray, setStatusArray] = useState([
        {id:1,state:"ready"},
        {id:2,state:"hide"},
        {id:3,state:"busy"}
      ]);
      const handleProductStatusChange = (value) => {
        setstatus(value)
      };

      

  
    
      const [formError, setFormError] = useState(false);

   




      const handleAddUser = () => {
        if (isValidUser()) {
          const newUser = {
            nameAr: nameAr,
            nameEn:nameEn,
            status:status
          };
          //console.log(newUser);
          axios({
            method: "put",
            url: api+"areas/"+item.id,
            headers: { 
                'Accept': 'application/json',
                'Authorization': 'Bearer '
            },
            data:newUser
        })
        .then((response) => {
             console.log(response.data);
             alert("update done")
             setError("done")
        })
        .catch((error) => {
          console.log(error.response.data)
        });
        } else {
          console.log('Invalid category!' + isValidUser() );
        }
      };
    
 

      const isValidUser = () => {
        if (
            nameAr !== '' &&
            nameEn !== '' &&
            status !== '' 
        ) {
          setError('')
          return true;
        } else {
          let message = 'Invalid user: ';
          setError(message)
         if ( nameAr === '') {
            setError( message += 'nameAr is empty.');
          }
          else if ( nameEn === '') {
            setError( message += 'nameEn is empty.');
          }
          else if ( status === '') {
            setError( message += 'status is empty.');
          }
          else{
            setError( message += 'error');

          }
          
          return false;
        }
      };

      useEffect(() => {

      }, [])
    return(
      <View style={[styles.container,{minHeight:1080}]}>
      <NavBar navigation={navigation}/>
      <NavBar2 navigation={navigation} nav2active={1}/>
      <NavBar3 navigation={navigation} nav2active={2}/>



        
        <View style={styles.container2}>

         

          {/* all text input */}
          <View>


{/* genral info  */}
<View style={styles.formaView}>
<Text style={styles.formaViewHeader}>areas</Text>


      {/* 4 row input */}
<View style={{flexDirection:"row",alignItems:"center"}}>

 {/* input nameAr */}
 <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>Name in arabic*</Text>
<TextInput
  value={nameAr}
  onChangeText={setnameAr}
  placeholder="Enter category name in arabic"
  style={styles.TextInput}

/>
</View>
{/* end of input Name */}

  {/* input nameEn */}
  <View style={styles.inputView}>
      <Text style={styles.inputHeaderText}>name in english</Text>
      <TextInput
        value={nameEn}
         onChangeText={setnameEn}
        placeholder="Enter category name in english"
        style={styles.TextInput}

      />
      </View>
      {/* end of input email */}

 {/* 4 row input */}
 <View style={styles.ViewInput2Row}>
 <View style={styles.inputView}>
  <Text style={styles.inputHeaderText}> Status</Text>
  <Picker
      selectedValue={status}
      onValueChange={handleProductStatusChange}
      style={styles.TextInput}>
      {StatusArray.map((item, index) => (
        <Picker.Item
          key={item.id}
          label={item.state}
          value={item.state}
        />
      ))}
  </Picker>
</View>
  </View>




        </View>
      </View>
      {/* end of genral info  */}








      



</View>
        </View>
        
        <View style={styles.saveView}>
            {error && <Text style={{ color: 'red' }}>{error}</Text>}

                <TouchableOpacity style={styles.Button}        onPress={handleAddUser}
     disabled={!isValidUser}
>
<Text style={styles.saveBtnText}>update</Text>
                {/* <Image 
                source={saveimg}  
                style={{width: 19, height: 19 ,margin:5}}
            /> */}
                </TouchableOpacity>

            </View>

           
</View>
    )
}

export default EditAreas;
