import React, { useState ,useEffect} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
    ,TextInput,Image,ScrollView,FlatList,StyleSheet
    ,Picker
    } from 'react-native'
    import NavBar from '../../navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../../../assets/colors/colors';
import NavBar2 from '../../navbar/NavBar2';
import NavBar3 from '../../navbar/NavBar3';

import world from '../../../assets/iconpng/gray/world.png'
import saveimg from '../../../assets/iconpng/black/save.png'
import axios from 'axios';
import {styles} from '../../../assets/styleSAP/formaInputSap'

import useTable from "../../../assets/hooks/useTable";
import { api } from '../../../assets/assets';
import TableShow from '../../table/TableShow';

import P from './P'



const PrintOrders = ({navigation}) => {
    
  const apilink='getorders';
  const pagetoEdit='EditProduct'
  const pageName="PrintOrders"

  const [data,setData]=useState([])
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const [headerColumns, setHeaderColumns] = useState([]);
  const [sortOrder, setSortOrder] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hiddenColumns, setHiddenColumns] = useState([]);

  const hide=[{header :"created_at"},{header :"updated_at"},{header :"address1"},{header :"image2"},{header :"image3"},{header :"image4"}
  // ,{header :"wieght"},{header :"hight"},{header :"gender"},{header :"birthday"}
]

  const[loading,setloading]=useState(0)

  const [headerColumnsOrginal, setheaderColumnsOrginal] = useState([]);


const refresh =()=>{
    axios({
        method: "get",
        url: api+apilink,
        headers: { 
            'Accept': 'application/json',
            'Authorization': 'Bearer '
        },
    })
    .then((response) => {
         console.log(response.data);
         setTableData(response.data);
         setData(response.data);
      setHeaderColumns(Object.keys(response.data[0] || {}));
      setheaderColumnsOrginal(Object.keys(response.data[0] || {}))
      setloading(1)
    })
    .catch((error) => {
        console.log(error);
    });
}
const [yearfrom, setYear] = useState(2023);
const [monthfrom, setMonth] = useState(1);
const [dayfrom, setDay] = useState(1);

const [yearto, setYearto] = useState(2023);
const [monthto, setMonthto] = useState(1);
const [dayto, setDayto] = useState(1);


const years = Array.from({length: 111}, (v, i) => 2023+ i);
const months = Array.from({length: 12}, (v, i) => i + 1);
const days = Array.from({length: 31}, (v, i) => i + 1);
  // Update the table data when the incoming data prop changes
 
const [ordernumber, setordernumber] = useState("");
const [paid, setpaid] = useState("yes");

const [csphone, setcsphone] = useState("");

 const serachorders=()=>{
  axios({
    method: "post",
    url: api+apilink,
    headers: { 
        'Accept': 'application/json',
        'Authorization': 'Bearer '
    },
    data:{
      from:yearfrom+"-"+monthfrom+'-'+dayfrom,
      to:yearto+"-"+monthto+"-"+dayto,
      csphone:csphone,
      id:ordernumber,
      paid:paid,
      state:""
    }
})
.then((response) => {
     console.log(response.data);
     setTableData(response.data);
     setData(response.data);
  setHeaderColumns(Object.keys(response.data[0] || {}));
  setheaderColumnsOrginal(Object.keys(response.data[0] || {}))
  setloading(1)

})
.catch((error) => {
    console.log(error);
});
 }
  useEffect(() => {
 
    
  }, []);





 
  const[TableHandleSearch,handleHeaderClick,handlesortedData,handleDownload]=useTable(data);

  
  
  return (
    <SafeAreaView style={[styles.container,{minHeight:1080}]}>
    <NavBar navigation={navigation}/>
    <NavBar2 navigation={navigation} nav2active={3}/>
    <NavBar3 navigation={navigation} nav2active={2}/>



      
    <View style={styles.container2}>
        <Text >{pageName} page</Text>
        <View style={styles.Button} >
{
            data&&
           <P items={data} dateofmeals={"30"+"-"+"05"+"-"+"2023"}/>

          }
</View>
    {/* Tools  */}
    <View style={styles.formaView}>
<Text style={styles.formaViewHeader}>dates</Text>
 {/* 4 row input */}
 <View style={{flexDirection:"row",alignItems:"center"}}>
 <View style={styles.inputView}>
<Text style={styles.inputHeaderText}>from</Text>
<Picker
        selectedValue={yearfrom}
        onValueChange={(itemValue) => setYear(itemValue)}
        style={styles.dateInput}

      >
        {years.map((y) => <Picker.Item key={y} label={`${y}`} value={`${y}`} />)}
      </Picker>
      <Picker
        selectedValue={monthfrom}
        onValueChange={(itemValue) => setMonth(itemValue)}
        style={styles.dateInput}

      >
        {months.map((m) => <Picker.Item key={m} label={`${m}`} value={`${m}`} />)}
      </Picker>
      <Picker
        selectedValue={dayfrom}
        onValueChange={(itemValue) => setDay(itemValue)}
        style={styles.dateInput}

      >
        {days.map((d) => <Picker.Item key={d} label={`${d}`} value={`${d}`} />)}
      </Picker>

</View>

<View style={styles.inputView}>
<Text style={styles.inputHeaderText}>to</Text>
<Picker
        selectedValue={yearto}
        onValueChange={(itemValue) => setYearto(itemValue)}
        style={styles.dateInput}

      >
        {years.map((y) => <Picker.Item key={y} label={`${y}`} value={`${y}`} />)}
      </Picker>
      <Picker
        selectedValue={monthto}
        onValueChange={(itemValue) => setMonthto(itemValue)}
        style={styles.dateInput}

      >
        {months.map((m) => <Picker.Item key={m} label={`${m}`} value={`${m}`} />)}
      </Picker>
      <Picker
        selectedValue={dayto}
        onValueChange={(itemValue) => setDayto(itemValue)}
        style={styles.dateInput}

      >
        {days.map((d) => <Picker.Item key={d} label={`${d}`} value={`${d}`} />)}
      </Picker>

</View>

<TouchableOpacity style={styles.Button}
onPress={()=>serachorders()}
>
  <Text>search </Text>
</TouchableOpacity>

</View>

<View style={{flexDirection:"row",alignItems:"center"}}>

  {/* input order number */}
  <View style={styles.inputView}>
      <Text style={styles.inputHeaderText}>order number</Text>
      <TextInput
        value={ordernumber}
         onChangeText={setordernumber}
        placeholder="Enter order number"
        style={styles.TextInput}

      />
      </View>
      {/* end of input order number */}

        {/* input csphone */}
  <View style={styles.inputView}>
      <Text style={styles.inputHeaderText}>phone</Text>
      <TextInput
        value={csphone}
         onChangeText={setcsphone}
        placeholder="Enter phone"
        style={styles.TextInput}

      />
      </View>
      {/* end of input order number */}




        {/* input paid */}
  <View style={styles.inputView}>
      <Text style={styles.inputHeaderText}>paid</Text>
      <Picker
        selectedValue={paid}
        onValueChange={(itemValue) => setpaid(itemValue)}
        style={styles.dateInput}

      >
       <Picker.Item  label={"yes"} value={'yes'} />
       <Picker.Item  label={"no"} value={'no'} />
       <Picker.Item  label={"all"} value={''} />


      </Picker>
      </View>
      {/* end of input paid */}


    

      </View>


</View>
        {
             data&&
             data.map((item,index)=>
                <View style={{
                    width:"90%",borderWidth:1,borderColor:"black",margin:15,padding:10,
                    backgroundColor:"white"
                }}
                >
                    <Text style={{fontSize:18}}>Order Number :{item.order.id} 
                    </Text>
                    <Text>
                    customer name :{item.order.csname} 
                    | phone : {item.order.csphone}
                    </Text>
                    <Text>
                    date :{item.order.date} 
                    </Text>
                    <Text>
                    state : {item.order.state} Paid  : {item.order.paid}
                    </Text>
                    <Text>
                    country : {item.order.country}
                    </Text>
                    <Text>
                    address : {item.order.address}
                    </Text>

                    <Text>
                    items : 
                    </Text>
                    <Text>
                    -------------------------------------
                    </Text>


                    {item.items.map((item, index) => (
                    <View style={{flexDirection:"row",margin:5,marginVertical:10}}>
                    <Text >
                  id item : {item.item_id} name :  {item.name_ar} 
                    </Text>

                    <Text style={{fontWeight:"bold",marginHorizontal:2}} >
                      {item.qty}X
                    </Text>
                    <Text style={{marginHorizontal:2}}>
                     size : {item.at1_value}
                    </Text>
                    <Text style={{marginHorizontal:2}}>
                     price unit : {item.price}
                    </Text>

                    <Text style={{marginHorizontal:2}}>
                     total price : {item.total_amount}
                    </Text>
                    </View>

                    ))}
                   

                </View>

             )
        }

       

  </View>
  
  
  </SafeAreaView>
  );
  };
  
  

export default PrintOrders;