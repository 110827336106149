import {useDispatch,useSelector} from "react-redux";
import dataEntry from '../../assets/iconpng/dataEntry.png'
import shop from '../../assets/iconpng/shop.png'
import users from '../../assets/iconpng/users.png'
import subscribe from '../../assets/iconpng/subscribe.png'
import navs from "./navs";

const initalState = {
    nav:navs.mainNav,
    navActive:"",
    language:"ar",
   
        

      
};
const navbar=(state = initalState,action)=>{

const {type,payload}=action;
//payload is the despatch function to do in the state

switch(type){



            

    case "changelang":
   if(state.language=="ar"){return{...state,language:"en"}}
    else{return{...state,language:"ar" }}
  
            








}



return state;
}
export default navbar;