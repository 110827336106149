import React,{useState,useEffect} from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
,StyleSheet,Image,Animated
} from 'react-native'
import {useDispatch,useSelector} from "react-redux";
import {styles} from "../../assets/styleSAP/NavBarStylesSAP"
import logoutpng from '../../assets/iconpng/logout.png'
import loadinggif from '../../assets/icongif/loadingwhite.gif'
import done from '../../assets/icongif/donewhite.gif'
import { motion } from "framer-motion";
import { checkicon } from './icons';





const NavBar =({navigation})=>{
    const[loading,setLoading]=useState(0)
    const login=useSelector(state => state.login)
    const dispatch = useDispatch();
    const lang=useSelector(state =>state.navbar.language);
    const navActive=useSelector(state =>state.login.mainNav);
    const nav=useSelector(state =>state.login.mainNav);


    const[active,setActive]=useState(navActive)

    const [isOn, setIsOn] = useState(lang==="en"?false:true);
    const toggleSwitch = () => {
        setIsOn(!isOn)
        setTimeout(()=>
        
         
         dispatch({
             type : "changelang",
         })
        ,200) 
    }






    const [isHovered, setIsHovered] = useState(0);

    let i ='KitchinStack'


    const logout=()=>{
        setLoading(1)
         setTimeout(function(){
            setLoading(2)
            
        }, 700);
        setTimeout(function(){
            dispatch({type : "logout"})
        }, 2000);

         
    }

  
    useEffect(() => {
//  console.log(nav)
    }, [])


    return(
        <SafeAreaView style={styles.navBar}>
            <View style={styles.navBarMenu}>
            {
            nav&&nav.map((navTap,index)=>
            navTap==null?null:
            <TouchableOpacity 
            key={navTap.id}
            onPress={()=>
                {
                    dispatch({
                        type : navTap.dispatch,
                    })
                    setActive(navTap.id)
                    dispatch({
                        type : "resetNav3",
                    })
                    navigation.navigate("Main")
                    
                }
               }
            
            onMouseEnter={() => setIsHovered(navTap.id)}
      onMouseLeave={() => setIsHovered(0)}
            style={[styles.navBarButton,{backgroundColor:isHovered==navTap.id
                ||active===navTap.id
                ?"#D2B875":"#FBE09B"}]}
            >
                <Image 
                source={checkicon(navTap.icon)}  
                style={styles.logoIcon}
            />
            <Text style={styles.navBarButtonText}>
                {lang==="ar"?navTap.pageNameAr:
                navTap.pageNameEn}
            </Text>
        </TouchableOpacity>

            )
        }
            </View>



            <View style={styles.rightNavBar}>






            <TouchableOpacity 

            
onMouseEnter={() => setIsHovered(51)}
onMouseLeave={() => setIsHovered(0)}
style={[styles.navBarButton,{backgroundColor:isHovered==51 ?"#D2B875":"#FBE09B"}]}
>
<Text style={styles.navBarButtonText}>
    {login.name}
</Text>
</TouchableOpacity>


     

<TouchableOpacity 

onPress={()=>logout()}
onMouseEnter={() => setIsHovered(50)}
onMouseLeave={() => setIsHovered(0)}
style={[styles.navBarButton,{backgroundColor:isHovered==50 ?"#616161":null}]}
>
{
    loading===0?
            <Image 
                source={logoutpng}  
                style={{width: 24, height: 24 }}
            />
            :loading===1?
            <Image 
            source={loadinggif}  
            style={{width: 24, height: 24 }}
        />
        :
        <Image 
        source={done}  
        style={{width: 24, height: 24 }}
    />
}

</TouchableOpacity>
<View style={styles.navBarButton}>
    <Text style={styles.navBarButtonText}>en</Text>
</View>
<TouchableOpacity style={[styles.navBarButtonLang,{
    width:40,backgroundColor:"white"
    ,flexDirection:"row",justifyContent:isOn===true?"flex-start":"flex-end",
    borderRadius:20
  }]}
  onPress={()=>{
    toggleSwitch()


}}
  >

      <motion.div layout transition={'spring'}  style={{
       width: 15,
       height: 15,
       backgroundColor:"gray"
        ,borderRadius:15
      }}/>

  </TouchableOpacity>
  <View style={styles.navBarButton}>
    <Text style={styles.navBarButtonText}>ar</Text>
</View>


            </View>
   



        </SafeAreaView>
    )
  
}


export default NavBar;
