import React, { useState , useRef, useEffect } from 'react'
import {View,Text,SafeAreaView,TouchableOpacity
    ,TextInput,Image,ScrollView,FlatList,StyleSheet
    ,Picker,Linking
    } from 'react-native'
    import NavBar from '../../navbar/NavBar';
import {useDispatch,useSelector} from "react-redux";
import colors from '../../../assets/colors/colors';
import NavBar2 from '../../navbar/NavBar2';
import NavBar3 from '../../navbar/NavBar3';

import world from '../../../assets/iconpng/gray/world.png'
import saveimg from '../../../assets/iconpng/black/save.png'
import axios from 'axios';
import {styles} from '../../../assets/styleSAP/formaInputSap'

import useTable from "../../../assets/hooks/useTable";
import { api } from '../../../assets/assets';
import TableShow from '../../table/TableShow';
import alarm from '../../../assets/sounds/alarm.wav'

import P from '../print/P';



const LiveOrders = ({navigation}) => {
  const apilink='getorders';
  const pagetoEdit='EditProduct'
  const pageName="PrintOrders"

  const [data,setData]=useState([])
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const [headerColumns, setHeaderColumns] = useState([]);
  const [sortOrder, setSortOrder] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hiddenColumns, setHiddenColumns] = useState([]);

  const hide=[{header :"created_at"},{header :"updated_at"},{header :"address1"},{header :"image2"},{header :"image3"},{header :"image4"}
  // ,{header :"wieght"},{header :"hight"},{header :"gender"},{header :"birthday"}
]

  const[loading,setloading]=useState(0)

  const [headerColumnsOrginal, setheaderColumnsOrginal] = useState([]);


const refresh =()=>{
    axios({
        method: "get",
        url: api+apilink,
        headers: { 
            'Accept': 'application/json',
            'Authorization': 'Bearer '
        },
    })
    .then((response) => {
         console.log(response.data);
         setTableData(response.data);
         setData(response.data);
      setHeaderColumns(Object.keys(response.data[0] || {}));
      setheaderColumnsOrginal(Object.keys(response.data[0] || {}))
      setloading(1)
    })
    .catch((error) => {
        console.log(error);
    });
}

const currentDate = new Date();
const [year, setYear] = useState(currentDate.getFullYear());
const [month, setMonth] = useState(currentDate.getMonth() + 1);
const [day, setDay] = useState(currentDate.getDate());





 
const [ordernumber, setordernumber] = useState("");
const [paid, setpaid] = useState("yes");

const [csphone, setcsphone] = useState("");

//const audioRef = React.createRef();

const openLinks = async (links) => {
  let currentIndex = 0;
  // if(links.length>0){
  //   audioRef.current.play();
  // }
  //audioRef.current.play();
  while (currentIndex < links.length) {
    const link = links[currentIndex];
    console.log(links[currentIndex].order.state)
    if(links[currentIndex].order.state=='request'){
     // alert("yes")
      //audioRef.current.play();

      window.open("https://khaishaq8.com/PrintBill?id="+links[currentIndex].order.id, '_blank')

    }
    await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait for 2 seconds
    currentIndex++;
  }
};
 const serachorders=()=>{
  axios({
    method: "post",
    url: api+apilink,
    headers: { 
        'Accept': 'application/json',
        'Authorization': 'Bearer '
    },
    data:{
      from:2023+"-"+1+'-'+1,
      to:year+"-"+month+"-"+day,
      csphone:"",
      id:"",
      paid:"yes",
      state:""
    }
})
.then((response) => {
     console.log(response.data);
     setTableData(response.data);
     setData(response.data);
    openLinks(response.data)
  setHeaderColumns(Object.keys(response.data[0] || {}));
  setheaderColumnsOrginal(Object.keys(response.data[0] || {}))

  setloading(1)

})
.catch((error) => {
    console.log(error);
});
 }


 const [timer, setTimer] = useState(90); // Starting time in seconds
 const intervalId = useRef(null);

 const effectRan = useRef(false);

  useEffect(() => {
    if (timer == 0) {
    // alert("hell")
      serachorders()
    }


    intervalId.current = setInterval(() => {

      if (timer <= 0) {
        // Timer has reached zero, run function and reset timer
        console.log('Timer has reached zero');
        setTimer(90);
        console.log(timer)


  
      } else {
        console.log(timer)
        

        setTimer((prevTimer) => prevTimer - 1);

      }
    }, 1000); // Update timer every second
  
    return () => {
      // Clear interval when component unmounts to prevent memory leaks
      clearInterval(intervalId.current);

    };
  

  

    
    
  }, [timer==0]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secondsRemaining = seconds % 60;
    return `${minutes}:${secondsRemaining.toString().padStart(2, '0')}`
    
  }




 
  const[TableHandleSearch,handleHeaderClick,handlesortedData,handleDownload]=useTable(data);

  
  
  return (
    <SafeAreaView style={[styles.container,{minHeight:1080}]}>
    <NavBar navigation={navigation}/>
    <NavBar2 navigation={navigation} nav2active={3}/>
    <NavBar3 navigation={navigation} nav2active={2}/>



    {/* <audio src={alarm} ref={audioRef}/> */}

    <View style={styles.container2}>
        <Text >{pageName} page</Text>
     
    {/* Tools  */}

    <View>
      <Text style={{ fontSize: 30 }}>{formatTime(timer)}</Text>
    </View>
  
        {
             data&&
             data.map((item,index)=>
                <View style={{
                    width:"90%",borderWidth:1,borderColor:"black",margin:15,padding:10,
                    backgroundColor:"white"
                }}
                >
                    <Text style={{fontSize:18}}>Order Number :{item.order.id} 
                    </Text>
                    <Text>
                    customer name :{item.order.csname} 
                    | phone : {item.order.csphone}
                    </Text>
                    <Text>
                    date :{item.order.date} 
                    </Text>
                    <Text>
                    state : {item.order.state}
                    </Text>
                    <Text>
                    Paid  : {item.order.paid}
                    </Text>
                    <Text>
                    area : {item.order.country}
                    </Text>
                    <Text>
                    address : {item.order.address}
                    </Text>

                    <Text>
                    items : 
                    </Text>
                    <Text>
                    -------------------------------------
                    </Text>


                    {item.items.map((item, index) => (
                    <View style={{flexDirection:"row",margin:5,marginVertical:10}}>
                    <Text >
                    {item.qty}X -  {item.name_ar} 
                    </Text>

                    <Text style={{fontWeight:"bold",marginHorizontal:2}} >
                      
                    </Text>
                    <Text style={{marginHorizontal:2}}>
                     notes : {item.at1_value}
                    </Text>
                    <Text style={{marginHorizontal:2}}>
                   ||   price unit : {item.price}
                    </Text>

                    <Text style={{marginHorizontal:2}}>
                     total price : {item.total_amount}
                    </Text>


                    </View>

                    ))}
                    <Text style={{marginHorizontal:2}}>
                     total amount : {item.totalAmount}
                    </Text>


                   
                   <TouchableOpacity style={styles.Button} onPress={()=>window.open("https://khaishaq8.com/PrintBill?id="+item.order.id, '_blank')}>
                        <Text>print</Text>
</TouchableOpacity>

                </View>

             )
        }

       

  </View>
  
  
  </SafeAreaView>
  );
  };
  
  

export default LiveOrders;