 import categoryicon from './icons/category.png'
 import updateicon from './icons/update.png'
 import producticon from './icons/product.png'
 import invoiceicon from './icons/invoice.png'
 import nike from './icons/nike.png'
 
 export const companyName ={id : 1,nameAr:"مطعم خيشة", nameEN:"resturant khaishaq8"}
// export const companyName ={id : 1,nameAr:" نظام دكتور شيف", nameEN:"Dr Chef System"}
export const version="1.4.4";

   //export const api='http://127.0.0.1:8000/api/';
  export const api='https://laravel.khaishaq8.com/api/';


export const icons={
    categoryicon:categoryicon,
    updateicon:updateicon,
    producticon:producticon,
    invoiceicon:invoiceicon,
    nike:nike
}


export const imageAPI = [];

for (let i = 1; i <= 427; i++) {
  imageAPI.push({
    id: i,
    imgurl: "https://www.flyfootapi.com/public/images/productimgs/" + i + ".png"
  });
}


