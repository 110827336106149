import React from "react";
import { View,Text } from "react-native";
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer } from '@react-navigation/native';
import NavBar from "../pages/navbar/NavBar";
import {useDispatch,useSelector} from "react-redux";
import Login from "../pages/login/Login";

import Main from "../pages/Main";

//users
import AddUser from "../pages/dataentry/users/AddUser";
import SearchUser from "../pages/dataentry/users/SearchUser";
import EditUser from "../pages/dataentry/users/EditeUser";


// table
import Table from "../pages/table/Table";
import AddCategory from "../pages/dataentry/category/AddCategory";
import SearchCategory from "../pages/dataentry/category/SearchCategory";
import EditCategory from "../pages/dataentry/category/EditCategory";
import AddNewProduct from "../pages/dataentry/products/AddNewProduct";
import SearchProduct from "../pages/dataentry/products/SearchProduct";
import EditProduct from "../pages/dataentry/products/EditProduct";
import SearchOrder from "../pages/dataentry/order/SearchOrder";
import PrintOrders from "../pages/dataentry/print/PrintOrders";
import SearchAreas from "../pages/dataentry/trademark/SearchAreas";
import EditAreas from "../pages/dataentry/trademark/EditAreas";
import AddArea from "../pages/dataentry/trademark/AddArea";
import LiveOrders from "../pages/dataentry/order/LiveOrders";
import LiveOrders2 from "../pages/dataentry/print/LiveOrders2";



const Stack = createNativeStackNavigator();



  const Navigation =()=>{

    const nav=useSelector(state =>state.navbar.nav);
    const login=useSelector(state => state.login.loginState)

    const HomeStack=()=>{
      return(
     <NavigationContainer>
        <Stack.Navigator >


           {/* country */}
           <Stack.Screen name="Main" component={Main} 
                    options={{
                      headerShown: false,
                    }}
                    />

     {/* users */}
     <Stack.Screen name="AddUser" component={AddUser} 
                                options={{ headerShown: false}}

                        />
                        <Stack.Screen name="SearchUser" component={SearchUser} 
                                options={{ headerShown: false}}

                        />
                        <Stack.Screen name="EditUser" component={EditUser} 
                                options={{ headerShown: false}}

                        />


                      {/* categories */}
                      <Stack.Screen name="AddCategory" component={AddCategory}  options={{ headerShown: false}}/>
                      <Stack.Screen name="SearchCategory" component={SearchCategory} options={{ headerShown: false}}/>
                      <Stack.Screen name="EditCategory" component={EditCategory} options={{ headerShown: false}}/>

                      {/* products */}
                      <Stack.Screen name="AddNewProduct" component={AddNewProduct} options={{ headerShown: false}}/>
                      <Stack.Screen name="SearchProduct" component={SearchProduct} options={{ headerShown: false}}/>
                      <Stack.Screen name="EditProduct" component={EditProduct} options={{ headerShown: false}}/>

                        
                        

         
                      <Stack.Screen name="SearchOrder" component={SearchOrder} options={{ headerShown: false}}/>
                      <Stack.Screen name="LiveOrders" component={LiveOrders} options={{ headerShown: false}}/>


                      


                      <Stack.Screen name="PrintOrders" component={PrintOrders} options={{ headerShown: false}}/>
                      <Stack.Screen name="LiveOrders2" component={LiveOrders2} options={{ headerShown: false}}/>


                      <Stack.Screen name="AddArea" component={AddArea} options={{ headerShown: false}}/>
                      <Stack.Screen name="SearchAreas" component={SearchAreas} options={{ headerShown: false}}/>
                      <Stack.Screen name="EditAreas" component={EditAreas} options={{ headerShown: false}}/>


                      
                      
                                

          










            {/* Table */}
            
            <Stack.Screen name="Table" component={Table} 
                                options={{ headerShown: false}}

                        />
          
          
        </Stack.Navigator>
     </NavigationContainer>
      )}



    const LoginStack=()=>{
      return(
      <NavigationContainer>
        <Stack.Navigator >

                      <Stack.Screen name="Login" component={Login} 
                                  options={{ headerShown: false}}
                        />

        </Stack.Navigator>
     </NavigationContainer>
      )
    }









      return (
       login==="false"?
        <LoginStack/>:
        <HomeStack/>
    )

  }

  export default Navigation;