import React,{useState,Component,useEffect,useRef} from 'react'
import { View, Text, TextInput, TouchableOpacity, StyleSheet,
    Image
} from 'react-native';
import {useDispatch,useSelector} from "react-redux";
import loadinggif from '../../assets/icongif/loading.gif'
import done2 from '../../assets/icongif/done2.gif'
import logo from '../../assets/imgs/logo.png'
import {styles} from "../../assets/styleSAP/loginSAP"
import { motion } from "framer-motion"
import colors from '../../assets/colors/colors';
import { api, companyName ,version} from '../../assets/assets';
import system from '../../assets/iconpng/newicons/system.png'
import phone from "../../assets/iconpng/newicons/phone.png"
import passwordicon from '../../assets/iconpng/newicons/password.png'
import loginicon from '../../assets/iconpng/newicons/login.png'
import loadingicon from '../../assets/iconpng/newicons/loading.png'
import doneicon from '../../assets/iconpng/newicons/done.png'
import axios from 'axios';


const Login = () => {
    const dispatch = useDispatch();
    const lang=useSelector(state =>state.navbar.language);
    const theme=useSelector(state =>state.theme.theme)

    
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [validationError, setValidationError] = useState(null);


  const [loading,setloading]=useState(0);
  
  const [isOn, setIsOn] = useState(lang==="en"?false:true);

  const [isOntheme, setisOntheme] = useState(theme==="dark"?false:true);

    const toggleSwitch = () => {
        setIsOn(!isOn)
        setTimeout(()=>
        
         
         dispatch({
             type : "changelang",
         })
        ,200) 
    }

    const toggleSwitchtheme = () => {
      setisOntheme(!isOntheme)
      setTimeout(()=>
      
       
       dispatch({
           type : "theDark",
       })
      ,200) 
  }
  const handleLogin = () => {
    setloading(1)
    setTimeout(function(){
        if (phoneNumber.length !== 8) {
            setValidationError('Phone number must be 8 digits');
            return;
          }
          else{
            axios({
              method: "post",
              url: api+"searchemployee",
              headers: { 
                  'Accept': 'application/json',
                  'Authorization': 'Bearer '
              },
              data:{phone:phoneNumber,password:password}
          })
          .then((response) => {
               console.log(response.data);
               setTimeout(function(){
                setloading(2)
            }, 700);

            setTimeout(function(){
                dispatch({
                    type : "login",
                    payload : {
                    phone:response.data.user,
                    name:response.data.name,
                    pages:response.data.pages,
                    mainNav:response.data.mainNav,
                    dataEntryNav2:response.data.dataEntryNav2

                    }
                })
            
                
            }, 1500);
          })
          .catch((error) => {
              console.log(error);
              alert("user or password wrong")
              setloading(0)
          });
         
        

      
          }
    }, 1000);
  
    // Add logic to handle the login process here
  };

  const handleResetPassword = () => {
    // Add logic to handle the reset password process here
  };

  useEffect(() => {
  }, []);

  return (
    <View style={styles.container}>

<View style={ styles.loginDiv}>


<View style={{
  flexDirection:"row",
  justifyContent:"space-between",
  width:"100%"
}}>


  <View style={{flexDirection:"row",
  
  }}>
  <View style={{margin:5}}>
    <Text style={styles.langtext}>en </Text>
</View>
<TouchableOpacity style={[styles.navBarButtonLang,{
    width:70,backgroundColor:"gray",height:30,padding:10
    ,flexDirection:"row",justifyContent:isOn===true?"flex-start":"flex-end",
    borderRadius:20
  }]}
  onPress={()=>{
    toggleSwitch()


}}
  >

      <motion.div layout transition={'spring'}  style={{
       width: 15,
       height: 15,
       backgroundColor:"white"
        ,borderRadius:15
      }}/>

  </TouchableOpacity>
  <View style={{margin:5}}>
    <Text style={
    styles.langtext
    }>عربي</Text>
</View>
  </View>
</View>




 

<View style={{flexDirection:"row"}}>
<Image 
        source={system}  
        style={{width: 30, height: 30 }}
    />
      <Text style={styles.title}>
        {lang=="ar"?companyName.nameAr:companyName.nameEN}
        </Text>
</View>



      {validationError && (
        <Text style={styles.validationError}>{validationError}</Text>
      )}
      <View style={{flexDirection:"row",width:"100%",height:80}}>

        <Image 
        source={phone}  
        style={{width: 30, height: 30 ,margin:5}}
    />
             <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.8,
        delay: 0.5,
        ease: [0, 0.71, 0.2, 1.01]
      }}
      whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} 
      style={styles.inputDiv}
      
    >
    

      <TextInput
        placeholder={lang==="ar"?"رقم الهاتف":"phone number"}
        keyboardType="number-pad"
        onChangeText={(text) => setPhoneNumber(text)}
        style={
        styles.input}
        value={phoneNumber}
      />
            </motion.div>
            </View>

            <View style={{flexDirection:"row",width:"100%",height:120}}>

<Image 
source={passwordicon}  
style={{width: 30, height: 30 ,margin:5}}
/>
            <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.8,
        delay: 0.5,
        ease: [0, 0.71, 0.2, 1.01]
      }}
      whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} 
      style={styles.inputDiv}
      
    >
      <TextInput
       
        placeholder={lang==="ar"?"كلمة السر":"passord"}
        secureTextEntry
        onChangeText={(text) => setPassword(text)}
        value={password}
        style={
        styles.input}
      />
    </motion.div>
    </View>
     
 
            <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.8,
              delay: 0.5,
              ease: [0, 0.71, 0.2, 1.01]
            }}
             whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} 
             style={
             styles.divbutton}
             
             >
            <TouchableOpacity  onPress={handleLogin}
            style={
            styles.button}
           >
            <Image 
source={loginicon}  
style={{width: 30, height: 30 ,margin:5}}
/>
        <Text
        style={
        styles.buttonText}
        
       >{
          lang==="ar"?"تسجيل الدخول":"Login"
        }</Text>
      </TouchableOpacity>
            </motion.div>

     

       
        
            <motion.div 
             initial={{ opacity: 0, scale: 0.5 }}
             animate={{ opacity: 1, scale: 1 }}
             transition={{
               duration: 0.8,
               delay: 0.5,
               ease: [0, 0.71, 0.2, 1.01]
             }}
            whileHover={{ scale: 1.01 }} whileTap={{ scale: 0.95 }}>
            {/* <TouchableOpacity 
         onPress={handleResetPassword}  style={styles.resetPasswordButton}>
        <Text style={styles.resetPasswordButtonText}>{
          lang=="ar"?"اعادة كلمة السر":"Reset Password"
        }</Text>
      </TouchableOpacity> */}
  
            </motion.div>
            <Text>v {version}</Text>
     
      {
        loading==0?null:loading==1?
        <>
        <Image 
        source={loadingicon}  
        style={{width: 24, height: 24 }}
    /><Text>Loading ...</Text>
        </>
        
    :
    <>
      <Image 
    source={doneicon}  
    style={{width: 24, height: 24 }}
/>
<Text>Done</Text>
    </>
  
      }
     
     </View>
    </View>
  );
};


export default Login;
