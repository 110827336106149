import navs from "./navs";

const initalState = {
    nav22:[],      
};
const navbar2Reducer=(state = initalState,action)=>{

const {type,payload}=action;
//payload is the despatch function to do in the state

switch(type){




}



return state;
}
export default navbar2Reducer;