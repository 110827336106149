import dataEntry from '../../assets/iconpng/newicons/keyboard.png'
import kitchinIcon from '../../assets/iconpng/newicons/kitchen.png'
import admin from '../../assets/iconpng/newicons/admin.png'
import cooking from '../../assets/iconpng/newicons/cooking.png'
import delivery from '../../assets/iconpng/newicons/delivery.png'
import print from '../../assets/iconpng/newicons/print.png'
import notchose from '../../assets/iconpng/newicons/notchose.png'
import meal from '../../assets/iconpng/newicons/meal.png'
import users from '../../assets/iconpng/newicons/users.png'
import discount from '../../assets/iconpng/newicons/discount.png'
import locationIcon from '../../assets/iconpng/newicons/map.png'
import filesIcon from '../../assets/iconpng/newicons/subscribe.png'
import boxIcon from '../../assets/iconpng/newicons/box.png'
import addIcon from '../../assets/iconpng/newicons/add.png'
import edit from '../../assets/iconpng/newicons/edit.png'
import img from '../../assets/iconpng/newicons/img.png'


import shop from '../../assets/iconpng/shop.png'
import subscribe from '../../assets/iconpng/subscribe.png'
import chefIcon from '../../assets/iconpng/gray/chef.png'
import tableIcon from '../../assets/iconpng/newicons/database.png'
import priceIcon from '../../assets/iconpng/gray/price.png'
import { icons } from '../../assets/assets'

import {useDispatch,useSelector} from "react-redux";

// const pages=useSelector(state =>state.login.pages);
const Test=()=>{
   const pages=useSelector(state =>state.login.pages);
   
   return [
      pages.map((index,item)=>item.pageName=="users"? {id:1,pageNameEn:"users",pageNameAr:"المستخدمين",icon:users,pageLink:"setUsersNav3"}:null),
      {id:2,pageNameEn:"categories",pageNameAr:"الفئات",icon:icons.categoryicon,pageLink:"setCatigoriesNav3"},
      {id:3,pageNameEn:"products",pageNameAr:"المنتجات",icon:icons.producticon,pageLink:"setProductsNav3"},
      {id:4,pageNameEn:"orders",pageNameAr:"الفواتير",icon:icons.invoiceicon,pageLink:"setOrdersNav3"},
      {id:5,pageNameEn:"areas",pageNameAr:"المناطق",icon:icons.nike,pageLink:"settradeNav3"},
   ] 

}
const checkicon=(icon)=>{
  return icon=="dataEntry"?dataEntry:null
}

const updatedMainNav = [
   {'id':1,'pageNameEn':"data entry",'pageNameAr':"ادخال البيانات",'icon':'dataEntry',dispatch:"setDataEntry"}
];
const navs={
  
   mainNav:
   updatedMainNav.map(navItem => {
      return {
        ...navItem,
        icon: checkicon(navItem.icon) // Evaluates the icon value as a variable
      };
    })
    ,
    

// nav bar 2
   // dataEntryNav2:
   // [
   //   pages.map((index,item)=>item.pagname=="users"? {id:1,pageNameEn:"users",pageNameAr:"المستخدمين",icon:users,pageLink:"setUsersNav3"}:null),
   //    {id:2,pageNameEn:"categories",pageNameAr:"الفئات",icon:icons.categoryicon,pageLink:"setCatigoriesNav3"},
   //    {id:3,pageNameEn:"products",pageNameAr:"المنتجات",icon:icons.producticon,pageLink:"setProductsNav3"},
   //    {id:4,pageNameEn:"orders",pageNameAr:"الفواتير",icon:icons.invoiceicon,pageLink:"setOrdersNav3"},
   //    {id:5,pageNameEn:"trademark",pageNameAr:"الماركات",icon:icons.nike,pageLink:"settradeNav3"},
   // ],

   dataEntryNav2:
   [
     {id:1,pageNameEn:"users",pageNameAr:"المستخدمين",icon:users,pageLink:"setUsersNav3"},
      {id:2,pageNameEn:"categories",pageNameAr:"الفئات",icon:icons.categoryicon,pageLink:"setCatigoriesNav3"},
      {id:3,pageNameEn:"products",pageNameAr:"المنتجات",icon:icons.producticon,pageLink:"setProductsNav3"},
      {id:4,pageNameEn:"orders",pageNameAr:"الفواتير",icon:icons.invoiceicon,pageLink:"setOrdersNav3"},
      {id:5,pageNameEn:"areas",pageNameAr:"المناطق",icon:icons.nike,pageLink:"settradeNav3"},
   ],



//nav bar 3
   userNav3:[
      {id:1,pageNameEn:"add new user",pageNameAr:"اضافة مستخدم جديد",icon:addIcon,pageLink:"AddUser"},
      {id:2,pageNameEn:"users table",pageNameAr:"جدول المستخدمين",icon:tableIcon,pageLink:"SearchUser"},
   ],


   categoryNav3:[
      {id:1,pageNameEn:"add new category",pageNameAr:"اضافة فئة جديدة",icon:addIcon,pageLink:"AddCategory"},
      {id:2,pageNameEn:"category table",pageNameAr:"جدول الفئات",icon:tableIcon,pageLink:"SearchCategory"},

   ],

   productNav3:[
      {id:1,pageNameEn:"add new product",pageNameAr:"اضافة منتج جديدة",icon:addIcon,pageLink:"AddNewProduct"},
      {id:2,pageNameEn:"products table",pageNameAr:"جدول المنتجات",icon:tableIcon,pageLink:"SearchProduct"},
   ],

   tradeNav3:[
      {id:1,pageNameEn:"add new area",pageNameAr:"اضافة منطقة جديدة",icon:addIcon,pageLink:"AddArea"},
      {id:2,pageNameEn:"areas table",pageNameAr:"جدول المناطق",icon:tableIcon,pageLink:"SearchAreas"},
   ],

   ordersNav3:[
      {id:1,pageNameEn:"orders table",pageNameAr:"الفواتيير",icon:addIcon,pageLink:"SearchOrder"},
      {id:2,pageNameEn:"print orders",pageNameAr:" طباعة الفواتيير",icon:tableIcon,pageLink:"PrintOrders"},
      {id:3,pageNameEn:"live orders",pageNameAr:"الفواتير مباشرة",icon:tableIcon,pageLink:"LiveOrders"},

      
   ],




}
    export default navs;