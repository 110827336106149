import React from 'react'
import {TouchableOpacity,} from 'react-native'
import { PDFDownloadLink, Page, Text, View, Document,Font,StyleSheet ,Image} from '@react-pdf/renderer';
import {styles} from '../../../assets/styleSAP/formaInputSap'
import '../../../../index.css'
import { icons } from '../../../assets/assets';

const P =({items,dateofmeals})=>{
     const Almarai_Regular = require('../../../assets/fonts/Almarai/Almarai-Regular.ttf');
     Font.register({ family: 'Almarai-Regular', src: Almarai_Regular });

    function getDateAsString(dateofmeals) {
      const today = new Date(dateofmeals);
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      const dateString = `${year}-${month}-${day}`;
      return dateString;
    }
    const stylesTitle = StyleSheet.create({
        title: {
          fontFamily:"Almarai-Regular",
          fontSize:16
        },

    formaView:{
      borderWidth:0.5,
      marginBottom:55
   
  },
      })
 

      const MyPDF = () => {
        return (
            <Document>

            {items.map((item, index) => (
              //<Page size={{ width: 200, height: 200 }}
             <Page
             size={{ width: 1080, height: "auto" }}
             style={{padding:20}}>

<View style={stylesTitle.formaView}>
  <View style={{borderWidth:0.5}}>
  <Text style={{alignSelf:"center",fontSize:45,fontWeight:"bold"}}>order number {item.order.id} 
 </Text> 
  </View>
     
    
      {/* item phone */}
  <View style={{borderWidth:0.5,padding:3}}>
<Text style={[styles.inputHeaderText,stylesTitle.title]}>date : {item.order.date}</Text>
</View>
{/* end of item phone */}

  {/* 4 row input */}
  <View style={{flexDirection:"row"}}>
 
  {/* item phone */}
  <View style={{borderWidth:0.5,padding:3}}>
<Text style={[styles.inputHeaderText,stylesTitle.title]}>phone :{item.order.csphone}</Text>
</View>
{/* end of item phone */}


 
 {/* item Name */}
 <View style={{borderWidth:0.5,padding:3}}>

<Text style={{fontFamily:"Almarai-Regular"}}>Name : {item.order.csname}</Text>

</View>
{/* end of item Name */}

</View>  

<View style={{borderWidth:0.5,padding:3}}>
<Text style={[styles.inputHeaderText,stylesTitle.title]}>country :{item.order.country}</Text>
</View>

<View style={{borderWidth:0.5,padding:3}}>
<Text style={[styles.inputHeaderText,stylesTitle.title]}>address :{item.order.address}</Text>
</View>


{item.items.map((item, index) => (
  <View style={{marginBottom:10}}>
      <View style={{flexDirection:"row"}}>
      
<View style={{borderWidth:0.5,padding:3}}>
<Text style={{fontFamily:"Almarai-Regular"}}>item name : {item.name_ar}</Text>
</View>

</View>
<View style={{flexDirection:"row",marginBottom:10}}>

<View style={{borderWidth:1.5,padding:3,borderColor:"red",margin:5}}>
<Text style={{fontFamily:"Almarai-Regular"}}>size : {item.at1_value}</Text>
</View>
<View style={{borderWidth:1.5,padding:3,borderColor:"red",margin:5}}>
<Text style={{fontFamily:"Almarai-Regular"}}>qty : {item.qty}</Text>
</View>
</View>
     <View style={{flexDirection:"row",alignItems:"center",height:285,margin:10}}>

     <View style={{   flexDirection:"row" ,padding:5,
       alignItems:"center",margin:5}}>
        
            <View 
                style={{
                  width:270,
              height:285,
              padding:5,
              backgroundColor:"white"
              ,marginTop:10
                }}
                >
                          <Text style={styles.inputHeaderText}>Image 1</Text>
    
               
                 <Image
                 source={{uri: item.image1}}
                 style={{width: "auto", height: 130,resizeMode:"cover",margin:3}}
               /> 
              
            
        
            
          </View>
          </View>
          <View style={styles.inputView}>
              <View 
            style={styles.imageTextInput}
                >
            <Text style={styles.inputHeaderText}>Image 2</Text>
    
               
                 <Image
                 src={item.image2}
                 style={{width: "auto", height: 130,resizeMode:"cover",margin:3}}
               /> 
              
            
        
            
          </View>
          </View>
          <View style={styles.inputView}>
          <View 
            style={styles.imageTextInput}
    
    
                >
            <Text style={styles.inputHeaderText}>Image 3</Text>
    
               
                 <Image
                 source={{uri: item.image3}}
                 style={{width: "auto", height: 130,resizeMode:"stretch",margin:3}}
               /> 
              
            
        
            
          </View>
          </View>
          <View style={styles.inputView}>
          <View 
            style={styles.imageTextInput}
                >
            <Text style={styles.inputHeaderText}>Image 4</Text>
    
               
                 <Image
                 source={{uri: item.image4}}
                 style={{width: "auto", height: 130,resizeMode:"cover",margin:3}}
               /> 
              
            
        
            
          </View>
          </View>
    
          </View>
          
          <View style={[
    {
      width: "100%",
      height: 1,
      backgroundColor: "black",
    },
  ]}
/>
      
          </View>
))}




<View style={{borderWidth:0.5,padding:3}}>
<Text style={{fontFamily:"Almarai-Regular"}}>end of order number : {item.order.id}</Text>
</View>

  


 {/* end of address  */}


 </View>   
 <Text style={{fontFamily:"Almarai-Regular",textAlign:"center"}}>*************************</Text>


              </Page> 
            ))}
          </Document>
        );
      };
    return(
        <View>
           
            <PDFDownloadLink document={<MyPDF />} fileName="mypdf.pdf"
            
        >
        {({ blob, url, loading, error }) =>
          loading ? 'Loading document...' : 'Print PDF'
        }
      </PDFDownloadLink>

        </View>
    )
}

export default P;